import {Observable} from '@PosterWhiteboard/observable';
import type {ItemObject, ItemType} from '@PosterWhiteboard/items/item/item.types';
import {openMessageModal} from '@Modals/message-modal';
import type {Page} from '@PosterWhiteboard/page/page.class';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import {showMessageGrowl} from '@Components/message-growl/message-growl';
import {getUserId, isUserDesigner} from '@Libraries/user.library';
import {openErrorModal} from '@Modals/error-modal';
import {ITEM_CONTROL_DIMENSIONS} from '@PosterWhiteboard/poster/poster-item-controls';
import {GROWL_TYPE} from '@Components/message-growl';
import type React from 'react';
import {createItemFromObject} from '@PosterWhiteboard/items/item/item-factory';
import type {FabricPositionalParams} from '@Utils/fabric.util';
import {copyToClipboard, readFromClipboard} from '@Utils/clipboard.util';
import {ElementDataType} from '@Libraries/add-media-library';
import type {ItemData} from './add-item.class';
import {isJson} from '@Utils/string.util';

interface ClipboardPasteInfo {
  isPasteFromRightClick: boolean;
  eventCanvasOffset?: FabricPositionalParams;
}

interface PosterClipboardData {
  key: string;
  graphicItemObjects: Array<ItemObject>;
  posterInfo: ClipboardPosterInfo;
  group: Record<string, number>;
}

interface ClipboardPosterInfo {
  width: number;
  height: number;
  idGalleryTemplate: string;
  idGalleryTemplateCreator: number;
}

export class PageClipboard extends Observable {
  public page: Page;

  public constructor(page: Page) {
    super();
    this.page = page;
  }

  public copySelection(e?: Event | React.MouseEvent<HTMLElement>): void {
    const selection = this.page.getSelectedItems();

    if (selection?.length) {
      let graphicItems: Array<ItemType> = [];

      for (let i = 0; i < selection.length; i++) {
        const selectionItem = selection[i];
        graphicItems.push(selectionItem);
      }
      if (graphicItems.length) {
        if (e) {
          e.preventDefault(); // default behaviour is to copy any selected text
        }
        graphicItems = this.page.activeSelection.orderGraphicItems(graphicItems);

        const graphicItemObjects = graphicItems.map((item) => {
          return item.toObject();
        });

        const {poster} = this.page;
        const group = this.page.activeSelection.getActiveObject();
        // if group is null this means only one graphic item is selected thus give the left and top coordinates of group same as x and y coordinates of graphic item
        const graphicItemsAndPoster = {
          key: poster.copyKey,
          graphicItemObjects,
          posterInfo: {
            width: poster.width,
            height: poster.height,
            idGalleryTemplate: poster.idGalleryTemplate,
            idGalleryTemplateCreator: poster.idGalleryTemplateCreator,
          },
          group: {
            left: group ? group.left : graphicItemObjects[0].x,
            top: group ? group.top : graphicItemObjects[0].y,
          },
        };
        const jsonString = JSON.stringify(graphicItemsAndPoster);
        void copyToClipboard(jsonString);
      } else {
        openMessageModal({
          title: window.i18next.t('pmwjs_copying_error_title'),
          text: window.i18next.t('pmwjs_title_copy_disabled'),
        });
      }
    } else {
      void this.checkAndCopyAudioSelection();
    }
  }

  public async checkAndCopyAudioSelection(): Promise<void> {
    const audioItem = this.page.poster.audioClips.getSelectedAudioItem();

    if (!audioItem) {
      return;
    }

    const {poster} = this.page;
    const jsonString = JSON.stringify({
      key: poster.copyKey,
      ...audioItem.toObject(),
    });
    await copyToClipboard(jsonString);
  }

  public async pasteItems(e?: Event | React.MouseEvent<HTMLElement>, clipboardPasteInfo: ClipboardPasteInfo = {isPasteFromRightClick: false}): Promise<void> {
    const {poster} = this.page;
    const graphicItemsAndPoster = await getItemsAndPosterDataFromClipboard();

    if (graphicItemsAndPoster) {
      if (this.isPasteAllowed(graphicItemsAndPoster)) {
        if (e) {
          e.preventDefault(); // We are already handling the data from the clipboard, we do not want it inserted into the document
        }
        showLoading('pastingGraphicItems');
        // if target poster has different dimensions than source poster paste the copied graphic items at (40,40) coordinate
        let deltaX = 15;
        let deltaY = 15;
        const graphicItems: Array<ItemType> = [];
        let multipleGraphicItems = graphicItemsAndPoster.graphicItemObjects.length > 1;

        if (!this.isMultipleItemsPasteAllowed(graphicItemsAndPoster) && multipleGraphicItems) {
          graphicItemsAndPoster.graphicItemObjects = graphicItemsAndPoster.graphicItemObjects.slice(0, 1);
          multipleGraphicItems = false;
          showMessageGrowl({text: window.i18next.t('pmwjs_only_one_item_paste_limit'), type: GROWL_TYPE.PREMIUM});
        }

        if (poster.width !== graphicItemsAndPoster.posterInfo.width || poster.height !== graphicItemsAndPoster.posterInfo.height) {
          deltaX = 40 - graphicItemsAndPoster.group.left;
          deltaY = 40 - graphicItemsAndPoster.group.top;
        }

        const graphicItemPromises = [];

        for (let i = 0; i < graphicItemsAndPoster.graphicItemObjects.length; i++) {
          graphicItemPromises.push(
            new Promise<void>((resolve, reject) => {
              createItemFromObject(this.page, graphicItemsAndPoster.graphicItemObjects[i])
                .then((graphicItem) => {
                  const templateCreator = graphicItemsAndPoster.posterInfo.idGalleryTemplateCreator;
                  if (!this.isItemPasteAllowed(graphicItemsAndPoster.key, graphicItem.idOriginalOwner, templateCreator)) {
                    hideLoading('pastingGraphicItems');
                    openErrorModal({
                      title: window.i18next.t('pmwjs_pasting_error_title'),
                      message: window.i18next.t('pmwjs_item_pasting_error'),
                    });
                    reject();
                    return;
                  }
                  if (graphicItemsAndPoster.posterInfo.idGalleryTemplate) {
                    graphicItem.idOriginalOwner = templateCreator;
                  }

                  // Only set coordinates of new graphic item if it's added through right click/touch event, and not keypress shortcut.
                  const eventOffsetX = clipboardPasteInfo.eventCanvasOffset?.left;
                  const eventOffsetY = clipboardPasteInfo.eventCanvasOffset?.top;

                  if (clipboardPasteInfo.isPasteFromRightClick && eventOffsetX !== undefined && eventOffsetY !== undefined) {
                    // not setting equal to popMenuOffset values just in case a group of items is being pasted in which case
                    // the subtraction in equations below is not equal to zero.
                    graphicItem.x = eventOffsetX + (graphicItem.x - graphicItemsAndPoster.group.left);
                    graphicItem.y = eventOffsetY + (graphicItem.y - graphicItemsAndPoster.group.top);
                  }

                  graphicItems.push(graphicItem);

                  resolve();
                })
                .catch(reject);
            })
          );
        }

        const responses = await Promise.allSettled(graphicItemPromises);

        for (const response of responses) {
          if (response.status === 'rejected') {
            console.error('Item failed to paste details:', response);
          }
        }

        if (clipboardPasteInfo.isPasteFromRightClick) {
          deltaX = multipleGraphicItems ? ITEM_CONTROL_DIMENSIONS.PMW_CONTROL_PADDING : graphicItems[0].getSelectorPadding();
          deltaY = deltaX;
        }

        this.page.activeSelection.duplicateSelection(graphicItems, deltaX, deltaY);
        hideLoading('pastingGraphicItems');
      } else {
        openMessageModal({
          title: window.i18next.t('pmwjs_pasting_error_title'),
          text: window.i18next.t('pmwjs_pasting_error'),
        });
      }
    }

    await this.pasteAudioItemIfInClipboard(e);
    await this.pasteExternalTextItemIfInClipboard(e);
  }

  public async pasteExternalTextItemIfInClipboard(e?: Event | React.MouseEvent<HTMLElement>): Promise<void> {
    const getExternalTextItemsDataIfExists = await getExternalTextItemDataFromClipboard();
    if (!getExternalTextItemsDataIfExists) {
      return;
    }

    if (e) {
      e.preventDefault();
    }

    const textItemData = {
      type: ElementDataType.TEXT,
      text: getExternalTextItemsDataIfExists,
    } as ItemData;
    void this.page.items.addItems.addTextItem(textItemData);
  }

  public async pasteAudioItemIfInClipboard(e?: Event | React.MouseEvent<HTMLElement>): Promise<void> {
    const getAudioItemsDataIfExists = await getAudioItemDataFromClipboard();
    if (!getAudioItemsDataIfExists) {
      return;
    }

    if (!this.isPasteAllowed(getAudioItemsDataIfExists, true)) {
      openMessageModal({
        title: window.i18next.t('pmwjs_pasting_error_title'),
        text: window.i18next.t('pmwjs_pasting_error'),
      });
    }

    if (e) {
      e.preventDefault();
    }

    if ('uid' in getAudioItemsDataIfExists) {
      this.page.poster.audioClips.duplicateAudio(getAudioItemsDataIfExists.uid as string);
    }
  }

  public isPasteAllowed(graphicItemsAndPoster: PosterClipboardData, isForAudioItem = false): boolean {
    if (isForAudioItem) {
      return window.PMW.currentUserOnPageLoad?.isSuper || graphicItemsAndPoster.key === this.page.poster.copyKey || !getUserId() || !isUserDesigner();
    }

    return (
      window.PMW.currentUserOnPageLoad?.isSuper ||
      graphicItemsAndPoster.key === this.page.poster.copyKey ||
      !graphicItemsAndPoster.posterInfo.idGalleryTemplate ||
      graphicItemsAndPoster.posterInfo.idGalleryTemplateCreator === getUserId() ||
      !getUserId() ||
      !isUserDesigner()
    );
  }

  public isItemPasteAllowed(copyKey: string, idOriginalOwner?: number, templateCreator?: number): boolean {
    return (
      window.PMW.currentUserOnPageLoad?.isSuper ||
      !getUserId() ||
      !isUserDesigner() ||
      copyKey === this.page.poster.copyKey ||
      idOriginalOwner === getUserId() ||
      templateCreator === getUserId() ||
      !(templateCreator || idOriginalOwner)
    );
  }

  /**
   * Checks if user is allowed to paste multiple items at a time
   */
  public isMultipleItemsPasteAllowed(graphicItemsAndPoster: PosterClipboardData): boolean {
    return (
      window.PMW.currentUserOnPageLoad?.isSuper ||
      graphicItemsAndPoster.key === this.page.poster.copyKey ||
      !graphicItemsAndPoster.posterInfo.idGalleryTemplate ||
      graphicItemsAndPoster.posterInfo.idGalleryTemplateCreator === getUserId()
    );
  }
}

const getDataFromClipboard = async (): Promise<unknown> => {
  let itemsAndPoster;
  try {
    const clipText = await readFromClipboard();
    itemsAndPoster = JSON.parse(clipText) as unknown;
  } catch (err) {
    return undefined;
  }

  return itemsAndPoster;
};

const getExternalTextDataFromClipboard = async (): Promise<string | undefined> => {
  let clipText: string;
  try {
    clipText = await readFromClipboard();
    if (isJson(clipText)) {
      return undefined;
    }
  } catch (err) {
    return undefined;
  }

  return clipText;
};

const getItemsAndPosterDataFromClipboard = async (): Promise<PosterClipboardData | undefined> => {
  let itemsAndPoster;

  try {
    const clipText = await readFromClipboard();
    itemsAndPoster = JSON.parse(clipText) as unknown;
  } catch (err) {
    return undefined;
  }

  const validateditemsAndPoster = getValidatedItemsAndPosterClipboardData(itemsAndPoster);
  if (validateditemsAndPoster) {
    return validateditemsAndPoster;
  }

  return undefined;
};

const getAudioItemDataFromClipboard = async (): Promise<PosterClipboardData | undefined> => {
  const itemsAndPoster = await getDataFromClipboard();

  if (!itemsAndPoster) {
    return undefined;
  }

  const validateAudioItemData = getItemsAndPosterClipboardDataForAudio(itemsAndPoster);
  if (validateAudioItemData) {
    return validateAudioItemData;
  }

  return undefined;
};

const getExternalTextItemDataFromClipboard = async (): Promise<string | undefined> => {
  const text = await getExternalTextDataFromClipboard();

  if (!text || (text && !text.trim())) {
    return undefined;
  }

  return text.trim();
};

export const getItemsAndPosterClipboardDataForAudio = (data: unknown): PosterClipboardData | undefined => {
  if (
    typeof data === 'object' &&
    Object.prototype.hasOwnProperty.call(data, 'uid') &&
    Object.prototype.hasOwnProperty.call(data, 'hashedFilename') &&
    Object.prototype.hasOwnProperty.call(data, 'name') &&
    Object.prototype.hasOwnProperty.call(data, 'source') &&
    Object.prototype.hasOwnProperty.call(data, 'onPosterStartTime') &&
    Object.prototype.hasOwnProperty.call(data, 'audioPlayer')
  ) {
    return data as PosterClipboardData;
  }
  return undefined;
};

export const getValidatedItemsAndPosterClipboardData = (data: unknown): PosterClipboardData | undefined => {
  if (
    typeof data === 'object' &&
    Object.prototype.hasOwnProperty.call(data, 'key') &&
    Object.prototype.hasOwnProperty.call(data, 'graphicItemObjects') &&
    Object.prototype.hasOwnProperty.call(data, 'posterInfo') &&
    Object.prototype.hasOwnProperty.call(data, 'group')
  ) {
    return data as PosterClipboardData;
  }
  return undefined;
};

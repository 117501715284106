import type {Subtitle} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle';
import {createAndAddSubtitleFromObject} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import {Item} from '@PosterWhiteboard/items/item/item.class';
import {isMobile} from 'react-device-detect';
import type {UpdateFromObjectOpts} from '@PosterWhiteboard/common.types';
import type {SubtitleObject} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle.types';
import {MOBILE_PIXEL_TARGET_FIND_TOLERANCE, PIXEL_TARGET_FIND_TOLERANCE} from '@PosterWhiteboard/items/transcript-item/transcript-item.types';
import type {TranscriptItemObject, TranscriptGeneratedFrom} from '@PosterWhiteboard/items/transcript-item/transcript-item.types';
import {FitContentLayout, Group, LayoutManager, type ObjectEvents, Point} from '@postermywall/fabricjs-2';
import {getPmwMlControl, getPmwMrControl, type OnResizeParams} from '@PosterWhiteboard/poster/poster-item-controls';
import {degreesToRadians} from '@Utils/math.util';
import {TextVerticalAlignType} from '@PosterWhiteboard/classes/text-styles.class';
import type {LetterCase} from '@Utils/string.util';
import {applyLetterCase} from '@Utils/string.util';
import type {DeepPartial} from '@/global';

export class TranscriptItem extends Item {
  declare fabricObject: Group;
  public gitype = ITEM_TYPE.TRANSCRIPT;

  public subtitlesHashmap: Record<string, Subtitle> = {};
  public verticalAlign: TextVerticalAlignType = TextVerticalAlignType.TOP;
  public generatedFrom?: TranscriptGeneratedFrom;

  private wasItemSelectedOnMouseDown = false;

  public toObject(): TranscriptItemObject {
    const subtitleObjects: Record<string, SubtitleObject> = {};

    for (const [key, item] of Object.entries(this.subtitlesHashmap)) {
      subtitleObjects[key] = item.toObject();
    }

    return {
      ...super.toObject(),
      subtitlesHashmap: subtitleObjects,
      generatedFrom: this.generatedFrom,
      verticalAlign: this.verticalAlign,
    };
  }

  public getSubtitleIdsInOrder(): Array<string> {
    return Object.entries(this.subtitlesHashmap)
      .sort(([, subtitleA], [, subtitleB]) => {
        const startTimeDifference = subtitleA.startTime - subtitleB.startTime;

        if (startTimeDifference !== 0) {
          return startTimeDifference;
        }

        return subtitleA.endTime - subtitleB.endTime;
      })
      .map(([uid]) => {
        return uid;
      });
  }

  public async onItemAddedToPage(): Promise<void> {
    await this.syncAllSubtitles();
  }

  public async updateFromObject(transcriptItemObject: DeepPartial<TranscriptItemObject>, {updateRedux = true, undoable = true}: UpdateFromObjectOpts = {}): Promise<void> {
    const {subtitlesHashmap, ...obj} = transcriptItemObject;

    this.copyVals({
      ...obj,
    });
    await this.init();

    if (subtitlesHashmap) {
      // delete items that are in this page but not in the itemsObject
      for (const [uid] of Object.entries(this.subtitlesHashmap)) {
        if (subtitlesHashmap[uid] === undefined) {
          this.removeSubtitle(uid);
        }
      }

      const updateFromObjectPromises = [];

      const addItemPromises = [];

      for (const [uid, subtitleObject] of Object.entries(subtitlesHashmap)) {
        if (subtitleObject) {
          if (this.subtitlesHashmap[uid]) {
            updateFromObjectPromises.push(
              this.subtitlesHashmap[uid].updateFromObject(subtitleObject, {
                undoable: false,
                updateRedux: false,
              })
            );
          } else {
            addItemPromises.push(createAndAddSubtitleFromObject(this, subtitleObject));
          }
        }
      }

      await Promise.all(addItemPromises);
      await Promise.all(updateFromObjectPromises);
    }

    await this.invalidate();

    if (undoable) {
      this.page.poster.history.addPosterHistory();
    }
    if (updateRedux) {
      this.page.poster.redux.updateReduxData();
    }
  }

  protected async updateFabricObject(): Promise<void> {
    await super.updateFabricObject();
    this.updateSubtitleVerticalAlignment();
  }

  private updateSubtitleVerticalAlignment(): void {
    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      if (this.verticalAlign === TextVerticalAlignType.TOP) {
        subtitle.fabricObject.setPositionByOrigin(new Point(-this.fabricObject.width / 2, -this.fabricObject.height / 2), 'left', 'top');
      } else if (this.verticalAlign === TextVerticalAlignType.CENTER) {
        subtitle.fabricObject.setPositionByOrigin(new Point(0, 0), 'center', 'center');
      } else if (this.verticalAlign === TextVerticalAlignType.BOTTOM) {
        subtitle.fabricObject.setPositionByOrigin(
          new Point(this.fabricObject.width / 2 - subtitle.fabricObject.width, this.fabricObject.height / 2 - subtitle.fabricObject.height),
          'left',
          'top'
        );
      }
    }
  }

  public getAnySubtitle(): Subtitle {
    return Object.values(this.subtitlesHashmap)[0];
  }

  public getCurrentSubtitle(): Subtitle | undefined {
    const currentTime = this.page.poster.getCurrentTime();
    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      if (subtitle.startTime <= currentTime && subtitle.endTime >= currentTime) {
        return subtitle;
      }
    }
    return undefined;
  }

  public async updateAllSubtitlesToLetterCase(letterCase: LetterCase): Promise<void> {
    const newSubtitlesHashmap: Record<string, DeepPartial<SubtitleObject>> = {};
    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      newSubtitlesHashmap[subtitle.subtitleUID] = {
        text: applyLetterCase(subtitle.text, letterCase),
      };
    }

    return this.updateFromObject({
      subtitlesHashmap: newSubtitlesHashmap,
    });
  }

  public updateAllSubtitles(subtitleObject: DeepPartial<SubtitleObject>): Promise<void> {
    const newSubtitlesHashmap: Record<string, DeepPartial<SubtitleObject>> = {};
    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      newSubtitlesHashmap[subtitle.subtitleUID] = subtitleObject;
    }

    return this.updateFromObject({
      subtitlesHashmap: newSubtitlesHashmap,
    });
  }

  public onScaling(): void {}

  protected initEvents(): void {
    super.initEvents();
    // this.fabricObject.on('mousedown:before', this.onMouseDownBefore.bind(this));
    // this.fabricObject.on('mouseup:before', this.onMouseUpBefore.bind(this));
  }

  private onMouseDownBefore(e: ObjectEvents['mousedown:before']): void {
    this.wasItemSelectedOnMouseDown = this.page.getSelectedItems()[0] === this;
  }

  public onTextEditingExit(): void {
    this.fabricObject.set({
      subTargetCheck: false,
      interactive: false,
    });
  }

  private onMouseUpBefore(e: ObjectEvents['mouseup:before']): void {
    if (e.isClick && this.wasItemSelectedOnMouseDown) {
      this.fabricObject.set({
        subTargetCheck: true,
        interactive: true,
      });
    } else {
      this.fabricObject.set({
        subTargetCheck: false,
        interactive: false,
      });
    }
  }

  private onMouseUp(e: ObjectEvents['mouseup']): void {
    if (e.isClick && this.wasItemSelectedOnMouseDown) {
      // this.fabricObject.set({
      //   subTargetCheck: true,
      //   interactive: true,
      // });
    }
  }

  public getDuration(): number {
    let lastSubtitleEndTime: number | undefined;
    let firstSubtitleStartTime: number | undefined;

    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      if (firstSubtitleStartTime === undefined || (firstSubtitleStartTime && subtitle.startTime < firstSubtitleStartTime)) {
        firstSubtitleStartTime = subtitle.startTime;
      }

      if (lastSubtitleEndTime === undefined || (lastSubtitleEndTime && subtitle.endTime > lastSubtitleEndTime)) {
        lastSubtitleEndTime = subtitle.endTime;
      }
    }

    if (lastSubtitleEndTime === undefined || firstSubtitleStartTime === undefined) {
      throw new Error(`Failed to get duration for transcript!`);
    }

    return lastSubtitleEndTime - firstSubtitleStartTime;
  }

  public getFonts(withVariation: boolean): Array<string> {
    const fonts: Array<string> = [];
    Object.values(this.subtitlesHashmap).forEach((subtitle) => {
      fonts.push(...subtitle.getFonts(withVariation));
    });
    return [...new Set(fonts)];
  }

  public async seek(time: number): Promise<void> {
    // TODO: Implement this
  }

  public isStreamingMediaItem(): boolean {
    return true;
  }

  protected getFabricObjectForItem(): Promise<Group> {
    const subtitleTextAndBackground: Array<Group> = [];

    return new Promise((resolve) => {
      Object.values(this.subtitlesHashmap).forEach((subtitle) => {
        subtitleTextAndBackground.push(subtitle.getFabricObject());
      });

      const groupItem = new Group(subtitleTextAndBackground, {
        ...super.getCommonOptions(),
        width: 300,
        height: 200,
        perPixelTargetFind: true,
        layoutManager: new LayoutManager(new FitContentLayout()),
      });
      resolve(groupItem);
    });
  }

  protected initCustomControls(): void {
    super.initCustomControls();
    const pmwMlControl = getPmwMlControl(this.onResizeWithLeftHandle.bind(this));
    const pmwMrControl = getPmwMrControl(this.onResizeWithRightHandle.bind(this));
    this.fabricObject.controls[pmwMlControl.key] = pmwMlControl.control;
    this.fabricObject.controls[pmwMrControl.key] = pmwMrControl.control;
  }

  protected setControlsVisibility(): void {
    super.setControlsVisibility();
    const isItemLocked = this.isLocked();
    this.fabricObject.setControlsVisibility({
      pmwMr: !isItemLocked,
      pmwMl: !isItemLocked,
    });
  }

  private onResizeWithRightHandle(event: OnResizeParams): void {
    const newWidth = this.fabricObject.width + event.delta / this.fabricObject.scaleX;
    if (newWidth < this.getMinWidth()) {
      return;
    }

    this.resizeSubtitlesWidth(newWidth);
  }

  private onResizeWithLeftHandle(event: OnResizeParams): void {
    const newWidth = this.fabricObject.width + event.delta / this.fabricObject.scaleX;
    if (newWidth < this.getMinWidth()) {
      return;
    }

    this.fabricObject.set({
      // width: newWidth,
      left: this.fabricObject.left - event.delta * Math.cos(degreesToRadians(this.fabricObject.angle)),
      top: this.fabricObject.top - event.delta * Math.sin(degreesToRadians(this.fabricObject.angle)),
    });
    this.resizeSubtitlesWidth(newWidth);
  }

  protected getMinWidth(): number {
    let maxSubtitleMinWidth = 0;

    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      const minItemWidth = subtitle.getMinWidth();
      if (minItemWidth > maxSubtitleMinWidth) {
        maxSubtitleMinWidth = minItemWidth;
      }
    }

    return maxSubtitleMinWidth;
  }

  private resizeSubtitlesWidth(width: number): void {
    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      subtitle.setWidth(width);
    }
    this.fabricObject.triggerLayout();
    this.updateSubtitleVerticalAlignment();
  }

  private getPixelTargetFindTolerance(): number {
    return isMobile ? MOBILE_PIXEL_TARGET_FIND_TOLERANCE : PIXEL_TARGET_FIND_TOLERANCE;
  }

  private removeSubtitle(uid: string): void {
    if (this.hasItem(uid)) {
      this.subtitlesHashmap[uid].onRemove();
      this.fabricObject.remove(this.subtitlesHashmap[uid].fabricObject);
      delete this.subtitlesHashmap[uid];
    }
  }

  public addSubtitle(itemToAdd: Subtitle): void {
    this.subtitlesHashmap[itemToAdd.subtitleUID] = itemToAdd;
    this.fabricObject.add(itemToAdd.fabricObject);
  }

  private hasItem(uid: string): boolean {
    return this.subtitlesHashmap[uid] !== undefined;
  }

  private async syncAllSubtitles(): Promise<void> {
    const promises = [];
    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      promises.push(subtitle.syncItem());
    }
    await Promise.all(promises);
  }
}

import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {UpdateFromObjectOpts} from '@PosterWhiteboard/common.types';
import type {TranscriptItem} from '@PosterWhiteboard/items/transcript-item/transcript-item';
import {DEFAULT_STROKE_WIDTH, TEXT_OUTLINE_STROKE_WIDTH_FACTOR, TextStyles} from '@PosterWhiteboard/classes/text-styles.class';
import {SyncSubtitleToPosterClock} from '@PosterWhiteboard/items/transcript-item/sync-subtitle-to-poster-clock';
import {rgbToHexString} from '@Utils/color.util';
import type {SubtitleObject} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle.types';
import {FitContentLayout, Group, LayoutManager, Rect, Textbox} from '@postermywall/fabricjs-2';
import {Fill} from '@PosterWhiteboard/classes/fill.class';
import {TemplateStyleType} from '@PosterWhiteboard/items/transcript-item/subtitle/template-styles';
import {ItemAura} from '@PosterWhiteboard/classes/item-aura.class';
import {addFontsAsync, getFontFamilyNameForVariations} from '@Libraries/font-library';
import type {DeepPartial} from '@/global';

const BACKGROUND_PADDING = 12;

export class Subtitle extends CommonMethods {
  public transcriptItem: TranscriptItem;
  public subtitleUID = '';
  public text = '';
  public startTime = 0;
  public endTime = 0;
  public isInitialzed = false;
  public hasUserEdited = false;
  public textStyles: TextStyles = new TextStyles();
  public backgroundFill: Fill = new Fill();
  public currentTemplateStyle: TemplateStyleType = TemplateStyleType.TEMPLATE_STYLE_ONE;
  public aura: ItemAura = new ItemAura();
  public backgroundBorderRadius: number = 0;

  public backgroundFabricObject!: Rect;
  public textFabricObject!: Textbox;
  public fabricObject!: Group;
  private readonly syncToPosterClock: SyncSubtitleToPosterClock;

  public constructor(transcriptItem: TranscriptItem) {
    super();
    this.transcriptItem = transcriptItem;
    this.syncToPosterClock = new SyncSubtitleToPosterClock(this);
    this.syncToPosterClock.initSyncToPosterClick();
  }

  public toObject(): SubtitleObject {
    return {
      subtitleUID: this.subtitleUID,
      text: this.text,
      startTime: this.startTime,
      endTime: this.endTime,
      hasUserEdited: this.hasUserEdited,
      currentTemplateStyle: this.currentTemplateStyle,
      textStyles: this.textStyles.toObject(),
      backgroundBorderRadius: this.backgroundBorderRadius,
      backgroundFill: this.backgroundFill.toObject(),
      aura: this.aura.toObject(),
    };
  }

  public async init(): Promise<void> {
    if (!this.isInitialzed) {
      this.isInitialzed = true;
      this.fabricObject = this.getFabricObject();
      this.initEvents();
    }
  }

  private initEvents(): void {
    this.textFabricObject.on('editing:exited', this.onTextEditingExit.bind(this));
    this.textFabricObject.on('changed', this.onTextChanged.bind(this));
  }

  private onTextEditingExit(): void {
    this.transcriptItem.onTextEditingExit();
  }

  private onTextChanged(): void {
    this.updateBackgroundDimensions();
  }

  public copyVals(obj: DeepPartial<SubtitleObject>): void {
    const {textStyles, aura, backgroundFill, ...plainObj} = obj;
    super.copyVals(plainObj);
    this.textStyles.copyVals(textStyles);
    this.aura.copyVals(aura);
    this.backgroundFill.copyVals(backgroundFill);
  }

  public async onAddedToTranscript(): Promise<void> {
    await this.syncItem();
  }

  public async updateFromObject(subtitleObject: DeepPartial<SubtitleObject>, {updateRedux = true, undoable = true}: UpdateFromObjectOpts = {}): Promise<void> {
    this.copyVals({
      ...subtitleObject,
    });

    await this.ensureFontsAreLoaded();
    await this.init();
    this.updateFabricObject();
    if (undoable) {
      this.transcriptItem.page.poster.history.addPosterHistory();
    }

    if (updateRedux) {
      this.transcriptItem.page.poster.redux.updateReduxData();
    }
  }

  public getFonts(withVariation: boolean): Array<string> {
    return [withVariation ? getFontFamilyNameForVariations(this.textStyles.fontFamily, this.textStyles.isBold, this.textStyles.isItalic) : this.textStyles.fontFamily];
  }

  private async ensureFontsAreLoaded(): Promise<void> {
    await addFontsAsync([this.textStyles.getFontFamilyToLoad()]);
  }

  public getMinWidth(): number {
    return this.textFabricObject.getMinWidth() + BACKGROUND_PADDING * 2;
  }

  public async syncItem(): Promise<void> {
    await this.syncToPosterClock.syncToPage(window.posterEditor?.whiteboard?.getCurrentTime() ?? 0);
  }

  public onRemove(): void {
    this.syncToPosterClock.unload();
  }

  public getFabricObject(): Group {
    this.textFabricObject = new Textbox(this.text, {
      lockMovementY: true,
      lockMovementX: true,
      lockScalingX: true,
      lockScalingY: true,
      hasControls: false,
      left: BACKGROUND_PADDING,
      top: BACKGROUND_PADDING,
    });

    this.backgroundFabricObject = new Rect({
      width: this.textFabricObject.width + BACKGROUND_PADDING * 2,
      height: this.textFabricObject.height + BACKGROUND_PADDING * 2,
      strokeWidth: 0,
      evented: false,
      selectable: false,
    });

    return new Group([this.backgroundFabricObject, this.textFabricObject], {
      subTargetCheck: true,
      interactive: true,
      layoutManager: new LayoutManager(new FitContentLayout()),
    });
  }

  public enterEditingMode(): void {
    this.textFabricObject.enterEditing();
  }

  public setWidth(width: number): void {
    this.backgroundFabricObject.set({
      width: width - this.backgroundFabricObject.strokeWidth,
      height: this.textFabricObject.height + BACKGROUND_PADDING * 2,
    });
    this.textFabricObject.set({
      width: this.backgroundFabricObject.width - BACKGROUND_PADDING * 2,
    });
    this.backgroundFabricObject.set({
      height: this.textFabricObject.height + BACKGROUND_PADDING * 2,
    });
    this.fabricObject.triggerLayout();
  }

  public show(): void {
    if (!this.fabricObject.visible) {
      this.fabricObject.set({
        visible: true,
        evented: true,
        selectable: true,

        subTargetCheck: true,
        interactive: true,
      });
      this.transcriptItem.page.fabricCanvas.requestRenderAll();
    }
  }

  public hide(): void {
    if (this.fabricObject.visible) {
      this.fabricObject.set({
        visible: false,
        evented: false,
        selectable: false,

        subTargetCheck: false,
        interactive: false,
      });
      this.transcriptItem.page.fabricCanvas.requestRenderAll();
    }
  }

  private applyTextStroke(): void {
    if (this.textStyles.stroke) {
      this.textFabricObject.set({
        strokeWidth: this.textStyles.fontSize * this.textStyles.strokeWidth * TEXT_OUTLINE_STROKE_WIDTH_FACTOR,
        strokeLineJoin: 'round',
        paintFirst: 'stroke',
        stroke: rgbToHexString(this.textStyles.strokeColor, 1),
      });
    } else if (!this.textStyles.isBold) {
      this.textFabricObject.set({
        strokeWidth: DEFAULT_STROKE_WIDTH,
        strokeLineJoin: 'miter',
        paintFirst: 'fill',
        stroke: undefined,
      });
    }
  }

  public updateFabricObject(): void {
    this.textFabricObject.set({
      text: this.text,
      shadow: this.transcriptItem.aura.getItemAura(),
      ...this.textStyles.getTextStyles(this.backgroundFabricObject.width, this.backgroundFabricObject.height),
    });

    this.applyTextStroke();

    this.backgroundFabricObject.set({
      rx: this.backgroundBorderRadius,
      ry: this.backgroundBorderRadius,
      fill: this.backgroundFill.getFill(this.backgroundFabricObject.width, this.backgroundFabricObject.height),
    });

    this.updateBackgroundDimensions();
    this.fabricObject.triggerLayout();
    this.transcriptItem.page.fabricCanvas.requestRenderAll();
  }

  private updateBackgroundDimensions(): void {
    this.backgroundFabricObject.set({
      width: this.textFabricObject.width + BACKGROUND_PADDING * 2,
      height: this.textFabricObject.height + BACKGROUND_PADDING * 2,
    });
  }

  public async addSubtitleToTranscript(): Promise<void> {
    this.transcriptItem.addSubtitle(this);
    await this.onAddedToTranscript();
  }
}

export const createAndAddSubtitleFromObject = async (transcriptItem: TranscriptItem, subtitleObject: DeepPartial<SubtitleObject>): Promise<Subtitle> => {
  const subtitle = new Subtitle(transcriptItem);
  await subtitle.updateFromObject(subtitleObject, {
    updateRedux: false,
    undoable: false,
  });
  await subtitle.addSubtitleToTranscript();
  return subtitle;
};

import {USER_AUDIO_SOURCE} from '@Libraries/user-audio-library';
import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {AudioClips} from '@PosterWhiteboard/classes/audio-clips/audio-clips.class';
import {SyncAudioToPosterClock} from '@PosterWhiteboard/classes/audio-clips/sync-audio-to-poster-clock.class';
import {AudioPlayer} from '@Libraries/audio-player/audio-player.class';
import type {AudioPlayerObject, FadeData} from '@Libraries/audio-player/audio-player.types';
import {AUDIO_PLAYER_EVENT} from '@Libraries/audio-player/audio-player.types';
import {getDefaultFadeDurationForAudioDuration} from '@Libraries/poster-audio-item.library';
import {EditorMediaEvent, trackPosterBuilderGA4Events} from '@Libraries/ga-events';
import type {DeepPartial} from '@/global';

export interface AudioItemAudioPlayerObject extends AudioPlayerObject {
  isPlaying: boolean;
}

export interface AudioItemUpdateFromObjectProps {
  undoable?: boolean;
  updateRedux?: boolean;
}

export interface AudioItemObject {
  uid: string;
  hashedFilename: string;
  audioPlayer: AudioItemAudioPlayerObject;
  source: USER_AUDIO_SOURCE;
  onPosterStartTime: number;
  name: string;
}

export class AudioItem extends CommonMethods {
  public audioClips: AudioClips;
  public uid = '';
  public hashedFilename = '';
  public source = USER_AUDIO_SOURCE.UPLOAD;
  public onPosterStartTime = 0;
  public name = '';
  public audioPlayer: AudioPlayer;
  private readonly syncToPosterClock: SyncAudioToPosterClock;

  public constructor(audioPlaylist: AudioClips) {
    super();
    this.audioClips = audioPlaylist;
    this.syncToPosterClock = new SyncAudioToPosterClock(this);
    this.syncToPosterClock.initSyncToPosterClick();

    this.audioPlayer = new AudioPlayer();
    this.initAudioPlayerEvents();
  }

  public async init(): Promise<void> {
    await this.audioPlayer.init();
  }

  public async updateFromObject(obj: DeepPartial<AudioItemObject>, {updateRedux = true, undoable = true}: AudioItemUpdateFromObjectProps = {}): Promise<void> {
    const {audioPlayer, ...audioItemObject} = obj;
    this.copyVals(audioItemObject);
    if (audioPlayer) {
      await this.audioPlayer.updateFromObject(audioPlayer);
    }

    if (undoable) {
      this.audioClips.poster.history.addPosterHistory();
    }
    if (updateRedux) {
      this.audioClips.poster.redux.updateReduxData();
    }
  }

  public async onToggleFade(): Promise<void> {
    let fadeData: FadeData = {
      fadeInDuration: getDefaultFadeDurationForAudioDuration(this.audioPlayer.getTrimmedDuration()),
      fadeOutDuration: getDefaultFadeDurationForAudioDuration(this.audioPlayer.getTrimmedDuration()),
    };

    if (this.audioPlayer.fade.fadeInDuration !== 0 || this.audioPlayer.fade.fadeOutDuration !== 0) {
      fadeData = {
        fadeInDuration: 0,
        fadeOutDuration: 0,
      };
      trackPosterBuilderGA4Events(EditorMediaEvent.Audio_Fade_Removed);
    } else {
      trackPosterBuilderGA4Events(EditorMediaEvent.Audio_Fade_Add);
    }

    await this.updateFromObject({
      audioPlayer: {
        fade: fadeData,
      },
    });
  }

  public shouldBePlaying(): boolean {
    return this.audioClips.poster.isPlaying() && this.isWithinPosterCurrentTime();
  }

  public isWithinPosterCurrentTime(): boolean {
    const posterCurrentTime = this.audioClips.poster.clock.getCurrentTime();
    return posterCurrentTime >= this.onPosterStartTime && posterCurrentTime < this.getOnPosterEndTime();
  }

  public getOnPosterEndTime(): number {
    return this.audioPlayer.getPlaybackDuration() + this.onPosterStartTime;
  }

  public copyVals(obj: DeepPartial<AudioItemObject>): void {
    const {audioPlayer, ...itemObj} = obj;
    super.copyVals(itemObj);
    if (audioPlayer) {
      this.audioPlayer.copyVals(audioPlayer);
    }
  }

  public toObject(): AudioItemObject {
    return {
      uid: this.uid,
      hashedFilename: this.hashedFilename,
      source: this.source,
      audioPlayer: {
        isPlaying: this.audioPlayer.isPlaying(),
        ...this.audioPlayer.toObject(),
      },
      onPosterStartTime: this.onPosterStartTime,
      name: this.name,
    };
  }

  public onRemove(): void {
    this.syncToPosterClock.unload();
    this.audioPlayer.unload();
  }

  public async seekToPosterTime(posterTime: number): Promise<void> {
    if (posterTime < this.onPosterStartTime || posterTime > this.getOnPosterEndTime()) {
      return;
    }

    await this.audioPlayer.seekToPlaybackTime(posterTime - this.onPosterStartTime);
  }

  public async play(): Promise<void> {
    if (!this.shouldBePlaying()) {
      return;
    }

    await this.audioPlayer.play();
  }

  private initAudioPlayerEvents = (): void => {
    this.audioPlayer.on(AUDIO_PLAYER_EVENT.ON_PLAY, () => {
      this.audioClips.poster.redux.updateAudioItemIsPlayingInRedux(this.uid, true);
    });
    this.audioPlayer.on(AUDIO_PLAYER_EVENT.ON_PAUSE, () => {
      this.audioClips.poster.redux.updateAudioItemIsPlayingInRedux(this.uid, false);
    });
    this.audioPlayer.on(AUDIO_PLAYER_EVENT.ON_STOP, () => {
      this.audioClips.poster.redux.updateAudioItemIsPlayingInRedux(this.uid, false);
    });
    this.audioPlayer.on(AUDIO_PLAYER_EVENT.ON_END, () => {
      this.audioClips.poster.redux.updateAudioItemIsPlayingInRedux(this.uid, false);
    });
  };

  public getEndTimeAccordingToPageDuration(pageDuration: number): number {
    const onPosterEndTime = this.getOnPosterEndTime();

    if (onPosterEndTime <= pageDuration) {
      return this.audioPlayer.trim.endTime;
    }

    const newDuration = pageDuration - this.onPosterStartTime;
    return this.audioPlayer.trim.startTime + newDuration;
  }
}

export const createAudioItemFromObject = async (audioPlaylist: AudioClips, audioItemObject: DeepPartial<AudioItemObject>): Promise<AudioItem> => {
  const audioItem = new AudioItem(audioPlaylist);
  audioItem.copyVals(audioItemObject);
  await audioItem.init();
  return audioItem;
};

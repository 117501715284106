import {Item} from '@PosterWhiteboard/items/item/item.class';
import type {BaseItemObject} from '@PosterWhiteboard/items/item/item.types';
import {doesVideoHaveAudio, loadVideo} from '@Utils/video.util';
import {PosterEvent} from '@PosterWhiteboard/poster/poster.types';
import {SyncToPosterClock} from '@PosterWhiteboard/classes/sync-to-poster-clock.class';
import type {Page} from '@PosterWhiteboard/page/page.class';
import type {ItemEffectsObject} from '@PosterWhiteboard/classes/item-effects.class';
import {ItemEffects} from '@PosterWhiteboard/classes/item-effects.class';
import type {ImageItemObject} from '@PosterWhiteboard/items/image-item/image-item.class';
import {loadImageAsync} from '@Utils/image.util';
import type {RGB} from '@Utils/color.util';
import {FabricImage} from '@postermywall/fabricjs-2';
import type {DeepPartial} from '@/global';

export interface MotionItemObject extends BaseItemObject {
  hashedFilename: string;
  duration: number;
  hasTransparency: boolean;
  frameRate: number;
  startTime: number;
  effects: ItemEffectsObject;
  endTime: number;
  isMuted: boolean;
}

/**
 * The version at which we enabled uniform stroke for motion items.
 */
const VERSION_2 = 2;
const PLAY_INTERRUPTED_BY_PAUSE_ERROR_MESSAGE = 'The play() request was interrupted by a call to pause()';

const GREEN_SCREEN_COLOR_RGB: RGB = [42, 250, 6];
export const DEFAULT_VIDEO_EXTENSION = 'webm';

export abstract class MotionItem extends Item {
  declare fabricObject: FabricImage;
  public hashedFilename = '';
  public duration = 0;
  public hasTransparency = false;
  public frameRate = 0;
  public startTime = 0;
  public effects: ItemEffects;
  public endTime = 0;
  public isMuted = false;
  public version = 2;
  public hasAudio: null | boolean = null;
  public htmlElement!: HTMLVideoElement;
  public syncToPosterClock: SyncToPosterClock;
  private currentLoadedImageFrameSrc?: string;
  /**
   * Sync to poster click function is cached in this variable so that it can be unmounted on item remove. Inline use of that
   * function doesn't work because of bind (https://stackoverflow.com/questions/28800850/jquery-off-is-not-unbinding-events-when-using-bind)
   */
  private readonly syncToPosterClockFunction;

  public constructor(page: Page) {
    super(page);
    // @ts-expect-error gota look into this ts error
    this.effects = new ItemEffects(this);
    this.syncToPosterClock = new SyncToPosterClock({
      getDuration: this.getStreamDuration.bind(this),
      getCurrentTime: this.getCurrentTime.bind(this),
      seek: this.seek.bind(this),
      pauseAtEnd: this.shouldPauseAtStreamEnd(),
    });
    this.syncToPosterClockFunction = this.syncToPosterClock.syncToPage.bind(this.syncToPosterClock);
  }

  public shouldPauseAtStreamEnd(): boolean {
    return false;
  }

  public hasUniformStroke(): boolean {
    return this.version >= VERSION_2;
  }

  public onRemove(): void {
    this.loading.removeLoading();
    this.page.poster.off(PosterEvent.PAGE_TIME_UPDATED, this.syncToPosterClockFunction);
    this.htmlElement.pause();
  }

  public getDuration(): number {
    return this.endTime - this.startTime;
  }

  public getStreamDuration(): number {
    return this.getDuration();
  }

  public toObject(): MotionItemObject {
    return {
      ...super.toObject(),
      effects: this.effects.toObject(),
      hashedFilename: this.hashedFilename,
      duration: this.duration,
      hasTransparency: this.hasTransparency,
      frameRate: this.frameRate,
      startTime: this.startTime,
      endTime: this.endTime,
      isMuted: this.isMuted,
    };
  }

  public async stop(): Promise<void> {
    await this.pause();
    this.htmlElement.currentTime = this.startTime;
  }

  protected setControlsVisibility(): void {
    super.setControlsVisibility();
    this.fabricObject.setControlsVisibility({
      ml: !this.isLocked(),
      mt: !this.isLocked(),
      mr: !this.isLocked(),
      mb: !this.isLocked(),
    });
  }

  public isStreamingMediaItem(): boolean {
    return true;
  }

  public copyVals(obj: DeepPartial<ImageItemObject>): void {
    const {effects, ...itemObj} = obj;
    super.copyVals(itemObj);
    this.effects.copyVals(effects);
  }

  public async updateFabricObject(): Promise<void> {
    await super.updateFabricObject();
    this.updateRemoveColorFilter();
    await this.effects.applyItemEffects();
    await this.effects.applyBorderEffects();
    this.htmlElement.muted = this.isMuted;
  }

  public async pause(): Promise<void> {
    this.htmlElement?.pause();
  }

  protected fixChanges(): void {
    this.applyFixForVersion2();
  }

  private applyFixForVersion2(): void {
    if (!this.hasUniformStroke()) {
      this.border.solidBorderThickness = Math.round(this.border.solidBorderThickness * this.scaleX);
      this.version = VERSION_2;
    }
  }

  public async onItemAddedToPage(): Promise<void> {
    this.page.poster.on(PosterEvent.PAGE_TIME_UPDATED, this.syncToPosterClockFunction);

    if (this.page.background.isTransparentBackground()) {
      this.page.background.updateBackgroundToBeNonTransparent();
    }
    await this.seekToPageTime();

    if (this.page.poster.isPlaying()) {
      await this.play();
    }
  }

  public getCurrentTime(): number {
    return this.htmlElement.currentTime - this.startTime;
  }

  public async seekToPageTime(): Promise<void> {
    await this.seek(this.page.poster.getCurrentTime());
    this.page.fabricCanvas.requestRenderAll();
  }

  public seek(time: number): Promise<void> {
    return new Promise<void>((resolve) => {
      this.htmlElement.addEventListener(
        'seeked',
        () => {
          resolve();
        },
        {
          capture: false,
          once: true,
        }
      );

      this.htmlElement.currentTime = time + this.startTime;
    });
  }

  public async play(): Promise<void> {
    if (!this.htmlElement) {
      throw new Error('Video element is not defined');
    }

    try {
      const promise = this.htmlElement.play();

      if (typeof promise !== 'undefined') {
        await promise;
      }
    } catch (e) {
      if (!(e instanceof Error && e.message.includes(PLAY_INTERRUPTED_BY_PAUSE_ERROR_MESSAGE))) {
        throw e;
      }
    }
  }

  public getFrameTimeForPosterTime(posterTime: number): number {
    return (posterTime > this.getDuration() ? posterTime % this.getDuration() : posterTime) + this.startTime + 0.001;
  }

  public async setImageFrame(src: string): Promise<void> {
    if (this.currentLoadedImageFrameSrc === src) {
      return;
    }

    const image = await loadImageAsync(src);
    this.fabricObject.setElement(image);
    this.fabricObject.set({
      scaleX: this.getScaledWidth() / image.width,
      scaleY: this.getScaledHeight() / image.height,
    });
  }

  protected async getFabricObjectForItem(): Promise<FabricImage> {
    await this.loadVideoElement();
    return new FabricImage(this.htmlElement, {
      ...super.getCommonOptions(),
      strokeUniform: true,
    });
  }

  protected async loadVideoElement(): Promise<void> {
    this.htmlElement = await loadVideo(this.getVideoUrl(), {videoElementAttributes: this.getVideoElementAttributes()});
    this.hasAudio = doesVideoHaveAudio(this.htmlElement);
    this.htmlElement.loop = !this.shouldPauseAtStreamEnd();
    this.htmlElement.muted = !this.hasAudio || this.isMuted;
    this.duration = this.htmlElement.duration;
    this.endTime = this.endTime === 0 ? this.htmlElement.duration : this.endTime;

    this.htmlElement.width = this.htmlElement.videoWidth;
    this.htmlElement.height = this.htmlElement.videoHeight;

    this.scaleItem();
  }

  /**
   * Scale image only if an already added image is loaded with a different dimension than it was the previous time loaded and saved
   */
  private scaleItem(): void {
    if (this.width && this.height && this.doesLoadedVideoHaveDifferentDimensions()) {
      this.scaleX = (this.scaleX * this.width) / this.getLoaderDisplayWidth();
      this.scaleY = (this.scaleY * this.height) / this.getLoaderDisplayHeight();
    }
  }

  private doesLoadedVideoHaveDifferentDimensions(): boolean {
    return this.width !== this.getLoaderDisplayHeight() || this.height !== this.getLoaderDisplayWidth();
  }

  protected getLoaderDisplayWidth(): number {
    return this.htmlElement.width;
  }

  protected getLoaderDisplayHeight(): number {
    return this.htmlElement.height;
  }

  protected getVideoElementAttributes(): Record<string, any> {
    return {};
  }

  protected abstract getVideoUrl(): string;

  protected isGreenScreenMp4Loaded(): boolean {
    return false;
  }

  private updateRemoveColorFilter(): void {
    if (this.isGreenScreenMp4Loaded()) {
      this.effects.removeColor = true;
      this.effects.removeColorValue = GREEN_SCREEN_COLOR_RGB;
    } else {
      this.effects.removeColor = false;
    }
  }
}

export const getDefaultFadeDurationForAudioDuration = (audioDuration: number): number => {
  if (audioDuration < 2) {
    return 0;
  }
  if (audioDuration >= 2 && audioDuration < 3) {
    return 0.5;
  }
  if (audioDuration >= 3 && audioDuration < 9) {
    return 1;
  }
  if (audioDuration >= 9 && audioDuration < 15) {
    return 2;
  }
  if (audioDuration <= 15) {
    return 3;
  }
  return 5;
};

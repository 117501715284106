import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {AudioItem} from '@PosterWhiteboard/classes/audio-clips/audio-item.class';
import {PosterEvent} from '@PosterWhiteboard/poster/poster.types';

/**
 * Max time difference in seconds that the audio and video can be out of sync
 */
const MAXIMUM_SYNC_THRESHOLD = 0.5;

export class SyncAudioToPosterClock extends CommonMethods {
  public audioItem: AudioItem;
  private syncing = false;
  private enable = true;
  private readonly boundSyncToPage;

  constructor(audioItem: AudioItem) {
    super();
    this.audioItem = audioItem;
    this.boundSyncToPage = this.syncToPage.bind(this);
  }

  public initSyncToPosterClick(): void {
    this.audioItem.audioClips.poster.on(PosterEvent.PAGE_TIME_UPDATED, this.boundSyncToPage);
  }

  public unload(): void {
    this.audioItem.audioClips.poster.off(PosterEvent.PAGE_TIME_UPDATED, this.boundSyncToPage);
  }

  public async syncToPage(time: number): Promise<void> {
    if (this.syncing || !this.enable) {
      return;
    }

    this.syncing = true;
    try {
      await this.syncItemToTime(time);
      await this.syncAudioPlaybackState();
    } finally {
      this.syncing = false;
    }
  }

  private async syncItemToTime(posterTime: number): Promise<void> {
    if (posterTime < this.audioItem.onPosterStartTime || posterTime > this.audioItem.getOnPosterEndTime()) {
      return;
    }

    const audioCurrentPlaybackTimeResptiveToPoster = this.audioItem.audioPlayer.getPlaybackCurrentTime() + this.audioItem.onPosterStartTime;
    if (Math.abs(posterTime - audioCurrentPlaybackTimeResptiveToPoster) > MAXIMUM_SYNC_THRESHOLD) {
      // if (this.audioItem.onPosterStartTime !== 0) {
      //   console.log('____seek', 'this.audioItem.onPosterStartTime', this.audioItem.onPosterStartTime, audioTrimmedCurrentTimeRespectiveToPoster, 'posterTime', posterTime);
      // }
      await this.audioItem.seekToPosterTime(posterTime);
    }
  }

  private async syncAudioPlaybackState(): Promise<void> {
    // if (this.audioItem.onPosterStartTime !== 0) {
    // console.log(
    //   'syncAudioPlaybackState onPosterStartTime',
    //   this.audioItem.onPosterStartTime,
    //   'currentPosterTime',
    //   this.audioItem.audioClips.poster.clock.getCurrentTime(),
    //   'isPlaying',
    //   this.audioItem.isPlaying(),
    //   'needToPlay',
    //   this.audioItem.shouldBePlaying() && !this.audioItem.isPlaying(),
    //   'needToPause',
    //   !this.audioItem.shouldBePlaying() && this.audioItem.isPlaying()
    // );
    // }
    if (this.audioItem.shouldBePlaying() && !this.audioItem.audioPlayer.isPlaying()) {
      await this.audioItem.play();
    } else if (!this.audioItem.shouldBePlaying() && this.audioItem.audioPlayer.isPlaying()) {
      this.audioItem.audioPlayer.pause();
    }
  }
}
